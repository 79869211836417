import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './profiles.css';
import './home-style.css';
import ItemsTab from './ItemsTab';
import Modal from './Modal';
import Soldout from './Soldout'



const ProfilesPage = () => {
    const [activeTab, setActiveTab] = useState('items');
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [editCategoryId, setEditCategoryId] = useState('');
    const [editCategoryName, setEditCategoryName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [categories, setCategories] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [originalCategoryName, setOriginalCategoryName] = useState('');

    const fetchCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('/php/categories.php');
            const data = await response.json();
            setTimeout(() => {
                setCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleAddCategoryClick = () => {
        setShowAddCategoryModal(true);
    };

    const handleCloseModal = () => {
        setShowAddCategoryModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setCategoryName('');
        setErrorWithTimeout('');
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setErrorWithTimeout('');
        setIsSubmitting(true);

        try {
            const response = await axios.post('/php/categories.php', {
                categoryName,
            });

            if (response.data.status === 'error' && response.data.message === 'Category already exists') {
                setErrorWithTimeout('Category already exists');
            } else {
                setTimeout(() => {
                    setIsSubmitting(false);
                    handleCloseModal();
                    fetchCategories();
                    showSuccess('Category added successfully');
                }, 500);
            }
        } catch (error) {
            setIsSubmitting(false);
            console.error('Error adding Category:', error.response || error);

            if (error.response) {
                if (error.response.status === 409) {
                    setErrorWithTimeout('Category already exists');
                } else {
                    setErrorWithTimeout('Unexpected error occurred: ' + (error.response.data.message || ''));
                }
            } else {
                setErrorWithTimeout('Network or server error occurred.');
            }
        }
    };



    const handleEditCategory = (category) => {
        setEditCategoryId(category.id);
        setEditCategoryName(category.category_name);
        setOriginalCategoryName(category.category_name);
        setShowEditModal(true);

    };

    const handleEditModalClose = () => {
        setShowEditModal(false);
        resetEditFormState();
    };

    const resetEditFormState = () => {
        setEditCategoryId('');
        setEditCategoryName('');
    };

    const handleUpdateCategory = async (e) => {
        e.preventDefault();

        setErrorWithTimeout('');
        setIsSubmitting(true);

        // Check if the name hasn't changed
        if (editCategoryName === originalCategoryName) {
            setIsSubmitting(false);
            handleEditModalClose();
            return;
        }

        try {
            const payload = {
                id: editCategoryId,
                category_name: editCategoryName,
            };
            const response = await axios.put(`/php/categories.php?id=${editCategoryId}`, payload);

            if (response.data.status === 'error' && response.data.message === 'Category already exists') {
                setErrorWithTimeout('Category already exists');
                setIsSubmitting(false);
            } else {
                setTimeout(() => {
                    setIsSubmitting(false);
                    handleEditModalClose();
                    fetchCategories();
                    showSuccess('Category updated successfully');
                }, 500);
            }
        } catch (error) {
            setIsSubmitting(false);
            console.error('Error updating category:', error);
            if (error.response) {
                if (error.response.status === 409) {
                    setErrorWithTimeout('Category already exists');
                } else {
                    setErrorWithTimeout('Unexpected error occurred: ' + (error.response.data.message || ''));
                }
            } else {
                setErrorWithTimeout('Network or server error occurred.');
            }
        }
    };




    const handleDeleteCategory = (category) => {
        setCategoryToDelete(category);
        setShowDeleteModal(true);
    };

    const confirmDeleteCategory = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            await axios.delete(`/php/categories.php`, {
                data: { id: categoryToDelete.id }
            });

            setTimeout(() => {
                setIsSubmitting(false);
                setShowDeleteModal(false);
                fetchCategories();
                showSuccess('Category deleted successfully');
            }, 500);
        } catch (error) {
            setIsSubmitting(false);

            if (
                error.response &&
                error.response.data &&
                error.response.data.message === "Can't delete as this category is still linked to an item."

            ) {
                setErrorWithTimeout(error.response.data.message);

            } else {
                setErrorWithTimeout('An error occurred while deleting the category.');
            }

            console.error('Error deleting category:', error);
        }
    };


    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const drinkCategoryIds = [8, 9, 11, 12];

    const sortedCategories = [
        ...categories.filter(category => !drinkCategoryIds.includes(Number(category.id))), 
        ...categories.filter(category => drinkCategoryIds.includes(Number(category.id))),  
    ];

    return (
        <main className="profiles-main">
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="profiles-container">
                {/* Sidebar */}
                <div className="sidebar">
                    <div className="tab-links">
                        <button
                            className={activeTab === 'items' ? 'active' : ''}
                            onClick={() => handleTabClick('items')}
                        >
                            Instock Items
                        </button>
                        <button
                            className={activeTab === 'outofstock' ? 'active' : ''}
                            onClick={() => handleTabClick('outofstock')}
                        >
                            Sold Out
                        </button>
                        <button
                            className={activeTab === 'categories' ? 'active' : ''}
                            onClick={() => handleTabClick('categories')}
                        >
                            Categories
                        </button>
                    </div>
                </div>

                {/* Main Content */}
                <div className="main-content">
                    {/* Conditional rendering based on activeTab */}
                    {activeTab === 'categories' && (
                        <>
                            <div className="top-right top-right-user">
                                <button className="profiles-btn add-btn" onClick={handleAddCategoryClick}>
                                    Add Category
                                </button>
                            </div>
                            <div className="categories-list">
                                {sortedCategories.length === 0 ? (
                                    <div className="no-categories-found">No categories found.</div>
                                ) : (
                                    sortedCategories.map((category, index) => (
                                        <div key={category.id} className="user">
                                            <div className='user-info'>
                                                <span className="user-number">({index + 1})</span>
                                                <span className='person-name'>{category.category_name}</span>
                                            </div>

                                            <div className='action-buttons'>
                                                <button className="edit-btn" onClick={() => handleEditCategory(category)}>
                                                    <i className="fas fa-pencil-alt"></i>
                                                </button>
                                                <button className="delete-btn" onClick={() => handleDeleteCategory(category)}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </>
                    )}

                    {activeTab === 'items' && (
                        <ItemsTab />
                    )}

                    {activeTab === 'outofstock' && (
                        <Soldout />
                    )}

                    {/* Add Category Modal */}
                    {showAddCategoryModal && (
                        <Modal
                            title="Add Category"
                            onClose={handleCloseModal}
                            onSubmit={handleFormSubmit}
                            submitButtonText="Add Category"
                            showCancel={false}
                            className="modal-content"
                            showSubmitButton={true}
                        >
                            <div className="profiles-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        value={categoryName}
                                        placeholder='Enter Category Name'
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        required
                                    />
                                </div>

                            </div>

                        </Modal>
                    )}
                    {error && (
                        <div className="error-overlay">
                            <div className='error-overlay-container'>
                                <i className="fas fa-exclamation-triangle"></i>
                                <p className="error-overlay-message">{error}</p>
                            </div>
                        </div>
                    )}

                    {isSubmitting && (
                        <div className="spinner-overlay">
                            <div className="spinner"></div>
                        </div>
                    )}

                    {/* Edit Category Modal */}
                    {showEditModal && (
                        <Modal
                            title={'Edit Category Name'}
                            onClose={handleEditModalClose}
                            onSubmit={handleUpdateCategory}
                            submitButtonText={'Update'}
                            showCancel={false}
                            showSubmitButton={true}
                            className="modal-content"
                        >
                            <div className="profiles-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        placeholder="New Category Name"
                                        value={editCategoryName}
                                        onChange={(e) => setEditCategoryName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </Modal>
                    )}


                    {/* Delete Category Modal */}
                    {showDeleteModal && (
                        <Modal
                            title="Delete Category"
                            onClose={() => setShowDeleteModal(false)}
                            onSubmit={confirmDeleteCategory}
                            submitButtonText="Delete"
                            showCancel={true}
                            showSubmitButton={true}
                            className="modal-content delete-modal-content"
                        >
                            <div>
                                <p className='delete-modal-heading'>Are you sure you want to delete this category?</p>
                            </div>
                        </Modal>
                    )}

                </div>
            </div>
        </main>
    );
};

export default ProfilesPage;
